import Inputmask from "inputmask";

function two(a) {
    return (9 < a ? "" : "0") + a;
}

function formatTime(a) {
    a = Math.floor(a / 1E3);
    let b = Math.floor(a / 60);
    let d = Math.floor(b / 60) / 24 | 0;
    let c = Math.floor(b / 60) % 24;
    a %= 60;
    b %= 60;
    return two(c) +":"+ two(b) + ":" + two(a)
};

// функция для склонения слов ( (1)"день", (2)"дня", (5)"дней")

function plural(str1, str2, str5) {
    return function (n) {
        return ((((n % 10) == 1) && ((n % 100) != 11)) ? (str1) : (((((n % 10) >= 2) && ((n % 10) <= 4)) && (((n % 100) < 10) || ((n % 100) >= 20))) ? (str2) : (str5)))
    }
}

// let days = plural('день', 'дня', 'дней');
// let hours = plural('час', 'часа', 'часов');
// let minutes = plural('минута', 'минуты', 'минут');
// let seconds = plural('секунда', 'секунды', 'секунд');



function Time() {
    let data = Date.parse('01/03/2010'); // дата начала 1 шестидневки  строго "месяц/день/год"
    data = new Date(data);
    data.setMinutes((-180 - data.getTimezoneOffset()), 0, 0); //для коррекции   запустить в зоне акции alert((new Date).getTimezoneOffset()) и поменять число

    for (; (new Date).getTime() > data;) {
        data.setDate(data.getDate() + 6)//через сутки +1 , через 6 дней +6
    }
    let a = data.getTime() - (new Date).getTime();

    if(document.getElementById("timer")){
        document.getElementById("timer").innerHTML = formatTime(a);
    }
    if(document.getElementById("timer-2")){
        document.getElementById("timer-2").innerHTML = formatTime(a);
    }
    if(document.getElementById("timer-3")){
        document.getElementById("timer-3").innerHTML = formatTime(a);
    }


    window.setTimeout(Time, 1E3)
}
Time();


let afterTomorrow =new Date(new Date().getTime()+172800000).toLocaleString('ru', {
    month: 'long',
    day: 'numeric'
});

if(document.querySelector(".modal-development__marker span")){
    document.querySelectorAll('.modal-development__marker').forEach(el => {
        el.querySelector("span").innerHTML = 'до '+afterTomorrow;
    });
}

if(document.querySelector(".intro__marker span")){
    document.querySelector(".intro__marker span").innerHTML = 'до '+afterTomorrow;
}
if(document.querySelector(".feedback-form__marker--1")){
    document.querySelector(".feedback-form__marker--1 span").innerHTML = 'до '+afterTomorrow;
}
