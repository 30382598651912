import tingle from "tingle.js";
import MicroModal from "micromodal";
import { event } from "./functions";

if (document.querySelector('.cases-main__review-play, .cases-inner__review-text')) {
    document.querySelector('.cases-main__review-play, .cases-inner__review-text').addEventListener('click', function () {
        let modalSurprise = new tingle.modal({
            onClose: function () {
                modalSurprise.destroy();
            }
        });
        modalSurprise.setContent('<video autoplay="true"  plays-inline=""><source src="review-gethead.mp4" type="video/mp4"></video>');
        modalSurprise.open();
    });
}
