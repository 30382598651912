import sass from '../scss/main.scss';
import Inputmask from "inputmask";
import MicroModal from 'micromodal';
import WOW from 'wow.js';
import 'lightgallery.js';
import Tabby from 'tabbyjs';
import {accordion} from './squeezebox';
import './timer';
import './slider';
import './input-file';
import './menu';
import './cases-btn-all';
import './map.js';
import './lead-generation.js';
import './hidden-block';
import './video-play';
import FormRequest from './form-request';
import {event, toggleClass} from './functions.js';


new FormRequest('.form-request');

new WOW().init();

import reviewGethead from '../video/review-gethead.mp4';

let $i = 1;
MicroModal.init({
    openTrigger: 'data-custom-open',
    disableScroll: true,
    disableFocus: true,
    awaitCloseAnimation: true,
    onShow: function (modal) {

        let modalClass = modal.id + $i;

        if (document.querySelector('#' + modal.id).classList.contains('modal-website-development')) {
            if (modalClass == modal.id + '1') {
                accordion("." + modal.id);
                document.querySelector('.' + modal.id).classList.add('active');
                $i++;
            } else {
                if (!document.querySelector('.' + modal.id).classList.contains('active')) {
                    accordion("." + modal.id);
                    document.querySelector('.' + modal.id).classList.add('active');
                    $i++;
                }
            }
        }

        if (modal.id == 'feedback') {
            let $text = document.querySelector('.feedback-form .checkbox-list--discount').textContent;
            document.querySelector('.modal-feedback-form .checkbox-list--discount').textContent = $text;
        }
    }
});


window.addEventListener('load', function () {
    if (!document.querySelector('.services-inner__list')) {
        accordion();
    }
});


document.querySelectorAll('input[type="tel"]').forEach(el => {
    Inputmask({
        "mask": "999 999 9999",
        "clearIncomplete": true,
        "placeholder": ""
    }).mask(el);
});


document.querySelectorAll('[data-tabs]').forEach((el) => {
    new Tabby('#' + el.id);
});


event('.checkbox-list input', 'click', function (e) {
    if (this.checked) {
        this.parentNode.parentNode.querySelectorAll('label').forEach(el => {
            el.classList.remove('active');
        });
        this.nextElementSibling.classList.add('active');
    } else {
        this.nextElementSibling.classList.remove('active');
    }
});

document.querySelectorAll('.lightgallery').forEach((el) => {
    lightGallery(el);
});


if (navigator.userAgent.match(/(iPad|iPhone|iPod|Android|Silk)/gi)) {
    document.querySelectorAll('.wow').forEach(el => {
        el.classList.remove('wow');
    });
}

let year = new Date().getFullYear()
document.querySelectorAll('.year').forEach(el => {
    el.outerHTML = year
});