import { event, toggleClass } from "./functions";


let lastScrollTop = 0;
window.onscroll = onScroll;


function onScroll(e) {
    let top = window.pageYOffset;
    let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

    if (document.querySelector('.header-main')) {
        if (currentScroll > 20) {
            if (lastScrollTop > top) {
                document.querySelector('.header-main').classList.remove('top-hide');
                document.querySelector('.header-main').classList.add('bot-vis');
            } else if (lastScrollTop < top) {
                document.querySelector('.header-main').classList.add('top-hide');
                document.querySelector('.header-main').classList.remove('bot-vis');
            }
        } else {
            document.querySelector('.header-main').classList.remove('bot-vis');
        }
        document.querySelectorAll('.header-main__services-toggle-wrap').forEach(el => {
            el.classList.remove('vis')
        });
        document.querySelectorAll('.header-main__services-title').forEach(el => {
            el.classList.remove('active')
        });
    }


    lastScrollTop = top;
}

if (document.querySelector('.header-main')) {
    setTimeout(function () {
        document.querySelector('.header-main').classList.add('vis');
    }, 300);
}


event('.hamburger', 'click', function (e) {
    toggleClass(document.body, 'overflow-hide');
    toggleClass(this, 'active');
    toggleClass(document.querySelector('.header-main__mobile-menu'), 'vis');
});


event('.header-main__services-list', 'click', function (e) {
    toggleClass(this.querySelector('.header-main__services-title'), 'active');
    toggleClass(this.querySelector('.header-main__services-toggle-wrap'), 'vis');
});


if (document.querySelector('.hamburger')) {
    window.addEventListener('resize', function () {
        if (document.querySelector('.hamburger').classList.contains('active')) {
            document.querySelector('.hamburger').click();
        }
    }, true);
}


function setActive() {
    let currentUrl = window.location.pathname,
        items = document.querySelectorAll('.header-main__nav li > a');

    items.forEach(item => {
        let href = item.getAttribute('href');
        if (currentUrl === href) {
            item.classList.add('active');
        }
    });
    

}

setActive();