import {event, toggleClass} from './functions.js';


event('.modern-technology__item', 'click', function (e) {
    e.preventDefault();

    toggleClass(this, 'active');

    if (this.classList.contains('active')) {

        document.querySelectorAll('.modern-technology__item.vis').forEach(el => {
            el.classList.remove('vis');
            el.classList.remove('active');
        });

        this.classList.add('vis');
    } else {
        this.classList.remove('vis');
    }
});
