import {event, toggleClass} from './functions.js';
import MicroModal from 'micromodal';

event('.modal--lead-generation .modal__btn', 'click', function (e) {
    let $this = this;
    let preloader = document.querySelector('.modal--lead-generation .spinner');
    preloader.classList.add('vis');
    $this.parentNode.parentNode.parentNode.classList.add('active');


    document.querySelector('.modal__step.active').classList.add('hide');




    setTimeout(function () {
        preloader.classList.remove('vis');
    }, 500);

    setTimeout(function () {
        if ($this.classList.contains('modal__btn--no')) {
            document.querySelector('.modal__step.active').nextElementSibling.nextElementSibling.classList.remove('hide');
        } else {
            document.querySelector('.modal__step.active').nextElementSibling.classList.remove('hide');
        }
        $this.parentNode.parentNode.parentNode.classList.remove('active');
    }, 800);
});