import Swiper from 'swiper';
import {event, toggleClass} from "./functions";

if (document.querySelector('.cases')) {
    new Swiper('.cases .swiper-container', {
        loop: true,
        speed: 700,
        slidesPerView: 1,
        spaceBetween: 15,
        navigation: {
            nextEl: '.cases .cases__next',
            prevEl: '.cases .cases__prev',
        },
        pagination: {
            el: '.cases .swiper-pagination',
            type: 'bullets',
            clickable: true,
        },

    });
}

if (document.querySelector('.reviews')) {
    let reviewsSwiper = new Swiper('.reviews .swiper-container', {
        loop: true,
        speed: 800,
        slidesPerView: 1,
        spaceBetween: 15,
        autoHeight: true,
        pagination: {
            el: '.reviews .swiper-pagination',
            type: 'bullets',
            clickable: true,
        },

    });

    event('a.reviews__more', 'click', function (e) {
        e.preventDefault();
        this.style.display = 'none';
        this.parentNode.parentNode.nextElementSibling.classList.add('vis');
        reviewsSwiper.updateAutoHeight();
    });
}

if (document.querySelector('.agency__slider')) {
    new Swiper('.agency__slider .swiper-container', {
        // loop: true,
        // speed: 3000,
        centeredSlides: true,
        // autoHeight: true,
        freeMode: true,
        slidesPerView: 'auto',
        spaceBetween: 20,
        breakpoints: {
            767: {
                spaceBetween: 10,
            },
        }
    });

    let boxElem = document.querySelector('.agency__slider .swiper-container ');
    let pointerElem = document.getElementById('pointer');

    function onMouseMove(event) {
        if (pointerElem.classList.contains('box-pointer-hidden')) {
            pointerElem.classList.remove('box-pointer-hidden');
        }

        boxElem.onmousemove = function(event){
            pointerElem.style.left = event.clientX +15+'px';
            pointerElem.style.top = event.clientY +15+'px';
        }
    }

    function disablePointer() {
        pointerElem.classList.add('box-pointer-hidden');
    }

    boxElem.addEventListener('mousemove', onMouseMove, false);
    boxElem.addEventListener('mouseleave', disablePointer, false);
}



if (document.querySelector('.how-to-find__images-slider')) {
    let howFindSwiper1 = undefined;
    let howFindSwiper2 = undefined;
    let howFindSwiper3 = undefined;

    function initHowFindSwiper() {
        let w=window,
            d=document,
            e=d.documentElement,
            g=d.getElementsByTagName('body')[0],
            x=w.innerWidth||e.clientWidth||g.clientWidth,
            y=w.innerHeight||e.clientHeight||g.clientHeight;


        if (x < 768 && howFindSwiper1 == undefined) {
            howFindSwiper1 = new Swiper(".s1", {
                slidesPerView: 'auto',
                spaceBetween: 15,
                pagination: {
                    el: '.s1 .swiper-pagination',
                    type: 'bullets',
                    clickable: true,
                },
            });

        } else if (x > 767 && howFindSwiper1 != undefined) {
            howFindSwiper1.destroy();
            howFindSwiper1 = undefined;
            document.querySelector('.s1 .swiper-wrapper').removeAttribute('style');
            document.querySelector('.s1 .swiper-slide').removeAttribute('style');
        }

        if (x < 768 && howFindSwiper2 == undefined) {
            howFindSwiper2 = new Swiper(".s2", {
                slidesPerView: 'auto',
                spaceBetween: 15,
                pagination: {
                    el: '.s2 .swiper-pagination',
                    type: 'bullets',
                    clickable: true,
                },
            });

        } else if (x > 767 && howFindSwiper2 != undefined) {
            howFindSwiper2.destroy();
            howFindSwiper2 = undefined;
            document.querySelector('.s2 .swiper-wrapper').removeAttribute('style');
            document.querySelector('.s2 .swiper-slide').removeAttribute('style');
        }

        if (x < 768 && howFindSwiper3 == undefined) {
            howFindSwiper3 = new Swiper(".s3", {
                slidesPerView: 'auto',
                spaceBetween: 15,
                pagination: {
                    el: '.s3 .swiper-pagination',
                    type: 'bullets',
                    clickable: true,
                },
            });

        } else if (x > 767 && howFindSwiper3 != undefined) {
            howFindSwiper3.destroy();
            howFindSwiper3 = undefined;
            document.querySelector('.s3 .swiper-wrapper').removeAttribute('style');
            document.querySelector('.s3 .swiper-slide').removeAttribute('style');
        }
    }


    window.addEventListener('resize', function () {
        initHowFindSwiper();
    }, true);


    event('a[data-custom-open="how-find"]', 'click', function (e) {
        setTimeout(function(){
            initHowFindSwiper();
        });
    });
}



if (document.querySelector('.adaptation-news .swiper-container')) {
    let howFindSwiper1 = undefined;

    function initNewsSwiper() {
        let w=window,
            d=document,
            e=d.documentElement,
            g=d.getElementsByTagName('body')[0],
            x=w.innerWidth||e.clientWidth||g.clientWidth,
            y=w.innerHeight||e.clientHeight||g.clientHeight;


        if (x < 768 && howFindSwiper1 == undefined) {
            howFindSwiper1 = new Swiper(".adaptation-news .swiper-container", {
                slidesPerView: 'auto',
                spaceBetween: 15,
            });

        } else if (x > 767 && howFindSwiper1 != undefined) {
            howFindSwiper1.destroy();
            howFindSwiper1 = undefined;
            document.querySelector('.adaptation-news .swiper-wrapper').removeAttribute('style');
            document.querySelector('.adaptation-news .swiper-slide').removeAttribute('style');
        }
    }


    window.addEventListener('resize', function () {
        initNewsSwiper();
    }, true);


    initNewsSwiper();
}