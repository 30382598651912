import {event, toggleClass} from './functions.js';

let $target = '';

if(document.querySelector(".cases-main")){
    $target = 'main';
}

if(document.querySelector(".cases-inner")){
    $target = 'inner';
}


event('.cases-'+$target+'__all-btn', 'click', function (e) {
    e.preventDefault();
    let btn = this;


    toggleClass(this, 'active');
    if (this.classList.contains('active')) {
        document.querySelectorAll('.cases-'+$target+'__item.hidden').forEach(el => {
            el.classList.remove('hidden');
            el.classList.add('vis');
        });

        setTimeout(function () {
            btn.textContent = 'Скрыть кейсы';
        }, 300);
    } else {
        document.querySelectorAll('.cases-'+$target+'__item.vis').forEach(el => {
            el.classList.add('hidden');
            el.classList.remove('vis');
        });

        setTimeout(function () {
            btn.textContent = 'Все кейсы';
        }, 300);
    }
});
