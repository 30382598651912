import MicroModal from "micromodal";

class FormRequest {
    constructor(selector) {
        this.requestForms = document.querySelectorAll(selector);
        return this.onSubmitHandler();
    }

    onSubmitHandler() {
        this.requestForms.forEach(form => {
            form.addEventListener('submit', this.sendRequest);
        })
    }

    sendRequest(event) {
        event.preventDefault();
        let form = event.target,
            btn = event.srcElement.querySelector('[type=submit]'),
            formData = new FormData(form),
            currentUrl = window.location.href;

        formData.append('path', currentUrl);

        btn.classList.add('loading');
        btn.disabled = true;

        fetch('/request.php', {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                },
                body: formData
            })
            .then(response => {
                if (response.ok) {
                    return Promise.resolve(response);
                } else {
                    return Promise.reject(new Error('Failed to load'));
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.status === false) {
                    document.querySelector('#form-error .modal__desc').innerHTML = data.message;

                    MicroModal.show('form-error');
                    // form.reset();
                } else {
                    document.querySelector('#form-success .modal__desc').innerHTML = data.message;
                    window.send_calltouch(form.querySelector('[name="phone"]').value);
                    ym(64761403, 'reachGoal', 'Order');
                    ga('send', 'event', 'button', 'Order');

                    fbq('track', 'Lead');
                    fbq('track', 'Order');

                    MicroModal.show('form-success');
                    form.reset();
                    document.querySelector('#file-name').innerHTML = '';
                }

                btn.classList.remove('loading');
                btn.disabled = false;
            })
            .catch(error => {
                MicroModal.show('form-error');
                console.log(`Error: ${error.message}`);
                btn.classList.remove('loading');
                btn.disabled = false;
            });
    }
}

export default FormRequest;